// // src/components/StatBlock.js
// import React from 'react';
// import { Paper, Typography } from '@mui/material';

// function StatBlock({ value, icon }) {
//   return (
//     <Paper elevation={3} sx={{ backgroundColor: '#F2BABA', padding: '10px', borderRadius: '10px', textAlign: 'center' }}>
//       <Typography variant="h6" sx={{ color: '#000' }}>
//         {value}
//       </Typography>
//       <Typography variant="body2" sx={{ color: '#000' }}>
//         {icon}
//       </Typography>
//     </Paper>
//   );
// }

// export default StatBlock;

import React from "react";
import "./statBlock.css";

function StatBlock({ value, icon }) {
    return (
        <div className="frame">
            <div className="div-wrapper">
                {icon}
            </div>
            <div className="div">
                <span className="text">{value}</span>
            </div>
        </div>
    );
}

export default StatBlock;
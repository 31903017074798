// src/components/WarStat.js
import React from 'react';
import './warStat.css'; // Стили отдельно
import Flag from './Flag';

function WarStat({ colors, name, type }) {
  const backgroundColor = type === 'win' ? '#4CAF50' : '#F44336'; // зеленый или красный цвет в зависимости от типа

  return (
    <div className="war-stat" style={{ backgroundColor, width: '90%' }}>
      <div style={{ marginRight: '10px' }}>
       <Flag colors={colors} size={25} />
      </div>
      <span className="country-name">{name}</span>
    </div>
  );
}

export default WarStat;

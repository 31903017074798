// src/hooks/useTelegram.js
import { useEffect } from 'react';
import axios from 'axios';
import { useAction } from '@reatom/npm-react';
import { updateUserAction } from '../atoms/userAtom';
import { useNavigate } from 'react-router-dom';

export function useTelegram() {
  const setUser = useAction(updateUserAction); // Используем экшен для обновления пользователя
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Telegram) {
      const telegram = window.Telegram.WebApp;
      telegram.ready();

      const { initData } = telegram; // Получаем initData
      const initDataRaw = telegram.initDataRaw; // Извлекаем initDataRaw

      if (initDataRaw) {
        // Используем авторизацию через заголовок
        const headers = {
          Authorization: `tma ${initDataRaw}`, // Передаем initDataRaw в заголовке
        };

        axios.post('https://cb.zatg.ru/api/auth', null, { headers })
          .then(response => {
            if (response.data.status === 'success') {
              setUser(response.data.user); // Обновляем пользователя через экшен
            } else {
              console.error('Authorization failed');
              navigate('/unauthorized'); 
            }
          })
          .catch(error => {
            console.error('Authorization failed', error.response?.data);
            navigate('/unauthorized'); 
          });
      } else {
        console.error('Telegram initDataRaw is missing');
        navigate('/unauthorized'); 
      }
    } else {
      console.error('Telegram object is not available');
      navigate('/unauthorized'); 
    }
  }, [setUser, navigate]);

  return { tg: window.Telegram?.WebApp };
}

// src/pages/WorldPage.js
import React from 'react';
import ActionButton from '../components/ActionButton';
import WarStat from '../components/WarStat';
import { Box, Typography } from '@mui/material';
// import NavigationIcon from '@mui/icons-material/Navigation';
import PublicIcon from '@mui/icons-material/Public';

function World() {
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#4A2C2C', overflow: 'auto' }}>
      <Box style={{ padding: '30px', margin: '15px 30px 20px', backgroundColor: '#f86868', borderRadius: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PublicIcon sx={{ fontSize: '2.5rem', color: '#4CAF50', backgroundColor: 'rgb(84, 60, 60, 0.8)', padding: '10px', borderRadius: '50%'  }} />
        <Typography variant="h6" sx={{ textAlign: 'center', color: '#FFF', fontSize: '14px', margin: '15px 25px' }}>The world is a place where you can fight opponents and colonize new provinces.</Typography>
      </Box>
      <div style={{ backgroundColor: 'rgba(255, 155, 155, 0.50)', padding: '10px', paddingBottom: '10px', margin: '15px 30px', borderRadius: '15px'}}>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
          <ActionButton icon='⚔️' label="Find the enemy" styles={{ width: '40%', height: '100px' }} bstyles={{ fontSize: '1rem' }} />
          <ActionButton icon='⭐️' label="Rating of alliances" styles={{ width: '40%', height: '100px' }} bstyles={{ fontSize: '1rem' }} />
        </div>

        {/* Пример отображения статуса войны */}
        <Box style={{ padding: '15px', margin: '10px', backgroundColor: '#543C3C', borderRadius: '15px' }}>
          <Typography variant="h6" sx={{ textAlign: 'center', color: '#FFF' }}>History of Wars:</Typography>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <WarStat colors={['#000000', '#FF0000', '#FFFF00']} name="Germany" type="win" />
            <WarStat colors={['#000000', '#FF0000', '#FFFF00']} name="Germany" type="defeat" />
            <WarStat colors={['#000000', '#FF0000', '#FFFF00']} name="Germany" type="defeat" />
            <WarStat colors={['#000000', '#FF0000', '#FFFF00']} name="Germany" type="win" />
            <WarStat colors={['#000000', '#FF0000', '#FFFF00']} name="Germany" type="win" />
          </div>
        </Box>
      </div>
    </div>
  );
}

export default World;

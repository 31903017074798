import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif', // Устанавливаем шрифт по умолчанию
  },
  palette: {
    primary: {
      main: '#f86868', // основной цвет (розовый)
    },
    background: {
      default: '#4A2C2C', // фон (темный оттенок)
      paper: '#543C3C', // цвет карточек и кнопок
    },
    text: {
      primary: '#FFFFFF', // основной текст белый
      secondary: '#7a7a7a'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px', // закругление кнопок
          color: 'white',
        },
      },
    },
  },
});

export default theme;

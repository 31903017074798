import React from 'react';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import BottomNav from './components/BottomNav';
import Profile from './pages/Profile';
import Government from './pages/Government';
import World from './pages/World';
import Unauthorized from './pages/Unauthorized';
import theme from './helpers/theme';
import { reatomContext } from '@reatom/npm-react';
import { createCtx } from '@reatom/framework';
import { useTelegram } from './hooks/useTelegram'; // Импортируем хук useTelegram
import './App.css';

const ctx = createCtx();

function AppRoutes() {
  const location = useLocation(); // Используем useLocation здесь

  return (
    <>
      <Routes>
        <Route path="/profile" element={<Profile />} />
        <Route path="/government" element={<Government />} />
        <Route path="/world" element={<World />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<Navigate to="/profile" />} />
      </Routes>
      {location.pathname !== '/unauthorized' && <BottomNav />}
    </>
  );
}

function App() {
  return (
    <reatomContext.Provider value={ctx}>
      <ThemeProvider theme={theme}>
        <Router>
          <div style={{ height: '100vh', backgroundColor: theme.palette.background.default, paddingBottom: '60px' }}>
            <AppWithTelegram /> {/* Оборачиваем в новый компонент */}
          </div>
        </Router>
      </ThemeProvider>
    </reatomContext.Provider>
  );
}

function AppWithTelegram() {
  useTelegram(); // Вызываем хук для загрузки данных при первом рендеринге
  return <AppRoutes />; // Возвращаем маршруты
}

export default App;

import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Иконка стрелки

function SSButton({ label, onClick, href, styles }) {
  return (
    <Button
      variant="contained"
      fullWidth
      sx={{
        margin: '10px auto',
        backgroundColor: '#D18F8F', // цвет кнопок
        borderRadius: '10px',
        width: '95%',
        display: 'flex', // Добавляем flex для выравнивания содержимого
        justifyContent: 'space-between',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: '#f86868', // цвет при наведении
        },
        ...styles,
      }}
      onClick={onClick} // Действие при клике
      href={href} // Если есть ссылка, будет навигация
    >
      <span style={{
        WebkitTextStrokeWidth: 1,
        WebkitTextStrokeColor: 'rgba(0, 0, 0, 0.20)',
        fontSize: '1.1rem'
      }}>{label}</span>
      <ArrowForwardIcon /> {/* Иконка стрелки справа */}
    </Button>
  );
}

export default SSButton;

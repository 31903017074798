// src/pages/Government.js
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import BottomNav from '../components/BottomNav';
import Flag from '../components/Flag'; // Импортируем компонент флага
import StatBlock from '../components/StatBlock'; // Импортируем компонент статистики
import ActionButton from '../components/ActionButton'; // Импортируем кнопки
import AddReactionIcon from '@mui/icons-material/AddReaction';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import CastleIcon from '@mui/icons-material/Castle';
import PeopleIcon from '@mui/icons-material/People';
import SecurityIcon from '@mui/icons-material/Security';
import LocalPoliceTwoToneIcon from '@mui/icons-material/LocalPoliceTwoTone';
function Government() {
  return (
    <div style={{ padding: '20px', backgroundColor: '#4A2C2C', height: '100%', width: '100%', overflow: 'auto' }}>
      {/* Основной контент */}
      <Box
        sx={{
          backgroundColor: '#D18F8F',
          padding: '20px',
          borderRadius: '15px',
          textAlign: 'center',
        }}
      >
        {/* Флаг */}
        <Flag colors={['#FFFFFF', '#0039A6', '#D52B1E']} styles={{ marginBottom: '10px' }} />

        {/* Название государства */}
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff', marginBottom: '10px' }}>
          Russia
        </Typography>

        {/* Статистика */}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4}>
            <StatBlock value="48%" icon={<AddReactionIcon sx={{color: '#0a8b41', fontSize: '2rem'}} />} />
          </Grid>
          <Grid item xs={4}>
            <StatBlock value="107" icon={<CastleIcon sx={{color: '#ff6600', fontSize: '2rem'}} />} />
          </Grid>
          <Grid item xs={4}>
            <StatBlock value="100 929" icon={<PeopleIcon sx={{color: '#0a668a', fontSize: '2rem'}} />} />
          </Grid>
          <Grid item xs={4}>
            <StatBlock value="17 272$" icon={<PaidRoundedIcon sx={{color: '#25b95b', fontSize: '2rem'}} />} />
          </Grid>
          <Grid item xs={4}>
            <StatBlock value="94829" icon={<LocalPoliceTwoToneIcon sx={{color: '#b82525', fontSize: '2rem'}} />} />
          </Grid>
          <Grid item xs={4}>
            <StatBlock value="92982" icon={<SecurityIcon sx={{color: '#1880e7', fontSize: '2rem'}} />} />
          </Grid>
        </Grid>
      </Box>

      {/* Второй блок с кнопками */}
      <Grid container spacing={2} sx={{ marginTop: '20px' }}>
        <Grid item xs={4}>
          <ActionButton label="Actions" icon="🕊️" />
        </Grid>
        <Grid item xs={4}>
          <ActionButton label="Decisions" icon="📜" />
        </Grid>
        <Grid item xs={4}>
          <ActionButton label="Gov" icon="👁️" />
        </Grid>
        <Grid item xs={4}>
          <ActionButton label="Alliance" icon="🏢" />
        </Grid>
        <Grid item xs={4}>
          <ActionButton label="Quests" icon="📄" />
        </Grid>
        <Grid item xs={4}>
          <ActionButton label="Upgrade" icon="🏭" />
        </Grid>
      </Grid>

      {/* Bottom Navigation */}
      <BottomNav />
    </div>
  );
}

export default Government;

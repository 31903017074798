// src/components/Flag.js
import React from 'react';
import { Box } from '@mui/material';

function Flag({ colors, size=90, styles }) {
  const width = size * 1.5; // Ширина флага в 1.5 раза больше высоты

  return (
    <Box 
      sx={{ 
        width: `${width}px`, 
        height: `${size}px`, 
        display: 'flex', 
        flexDirection: 'column', 
        margin: '0 auto', 
        borderRadius: '15px',
        ...styles
      }}
    >
      <Box 
        sx={{ 
          backgroundColor: colors[0], 
          flex: 1, 
          borderStartEndRadius: '15px', 
          borderStartStartRadius: '15px' 
        }} 
      />
      <Box 
        sx={{ 
          backgroundColor: colors[1], 
          flex: 1 
        }} 
      />
      <Box 
        sx={{ 
          backgroundColor: colors[2], 
          flex: 1, 
          borderEndStartRadius: '15px', 
          borderEndEndRadius: '15px' 
        }} 
      />
    </Box>
  );
}

export default Flag;

// src/atoms/userAtom.js
import { atom, action } from '@reatom/framework';

// Атом для хранения данных пользователя
export const userAtom = atom({
  id: null,
  username: '',
  first_name: '',
});

// Экшен для обновления пользователя
export const updateUserAction = action((userData) => userData);

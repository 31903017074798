// src/pages/Unauthorized.js
import React from 'react';

function Unauthorized() {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h1>Не авторизован</h1>
      <p>Пожалуйста, войдите через Telegram.</p>
    </div>
  );
}

export default Unauthorized;

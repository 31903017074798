// src/pages/Profile.js
import React from 'react';
import { useAtom } from '@reatom/npm-react';
import { userAtom } from '../atoms/userAtom';
import SSButton from '../components/CustomButton';

function ProfilePage() {
  const [user] = useAtom(userAtom); // Получаем данные пользователя из Atom

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <div style={{ margin: '0 auto 20px auto', paddingTop: '20px', display: 'flex', width: '95%', height: '25vh', flexDirection: 'column', alignItems: 'center', borderRadius: '15px', background: '#D18F8F' }}>
        <p style={{ color: 'white' }}>{user ? user.first_name : 'Firstname'} {user ? '[' + user.id + ']' : ''}</p>
        <p style={{ color: 'gray' }}>{user ? user.username : 'Username'}</p>
      </div>
      <SSButton label="Gov. Settings" />
      <SSButton label="Official Channel" href={'https://t.me/legacywars'} />
      <SSButton label="Our chat" href={'https://t.me/zadevv'} />
    </div>
  );
}

export default ProfilePage;

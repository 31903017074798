// src/components/ActionButton.js
import React from 'react';
import { Paper, Typography } from '@mui/material';

function ActionButton({ label, icon, styles, bstyles }) {
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: '#F2BABA',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        alignContent: 'center',
        height: '100%',
        borderRadius: '10px',
        color: '#000',
        cursor: 'pointer', // Добавим курсор для интерактивности
        '&:hover': {
          backgroundColor: '#f86868', // цвет при наведении
        },
        ...styles
      }}
    >
      <Typography variant="body2" sx={{fontSize: '1.3rem'}}>{icon}</Typography>
      <Typography variant="body2" sx={{ ...bstyles }}>{label}</Typography>
    </Paper>
  );
}

export default ActionButton;

import React, { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PublicIcon from '@mui/icons-material/Public';

function BottomNav() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleNavChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) navigate('/profile');
    if (newValue === 1) navigate('/government');
    if (newValue === 2) navigate('/world');
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: '#D18F8F' }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={handleNavChange}
        sx={{ width: '100%', height: '60px'}}
      >
        <BottomNavigationAction label="Profile" icon={<AccountCircleIcon />} />
        <BottomNavigationAction label="Government" icon={<ApartmentIcon />} />
        <BottomNavigationAction label="World" icon={<PublicIcon />} />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;
